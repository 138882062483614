<script>
	import { navigating } from '$app/stores';
	import '../app.css';
	import Footer from '$lib/components/Footer.svelte';
	import Navigation from '$lib/components/Navigation.svelte';
	import CookieBanner from '$lib/components/CookieBanner.svelte';
	import Spinner from '$lib/components/Spinner.svelte';
	import { status } from '$lib/stores/store.js';
	import { browser } from '$app/environment';
	import '@fontsource/titillium-web/200.css';
	import '@fontsource/titillium-web/300.css';
	import '@fontsource/titillium-web/400.css';
	import '@fontsource/titillium-web/600.css';
</script>

<svelte:head>
	<script
		id="Cookiebot"
		src="https://consent.cookiebot.com/uc.js"
		data-cbid="38c07a15-06b2-40b3-8529-54c4845241cc"
		data-blockingmode="auto"
		type="text/javascript"
	></script>
	<meta name="viewport" content="width=device-width, initial-scale=1" />
</svelte:head>

<main class="w-full h-full">
	{#if $navigating}
		<Spinner />
	{/if}
	<Navigation />
	<slot />
	<Footer />
</main>

<style>
	* {
		box-sizing: border-box;
		font-family: 'Titillium Web', sans-serif !important;
		font-weight: 200;
	}
</style>
