<script>
	import { onMount } from 'svelte';
	import Logo from '$lib/images/logo.svg';
	import { status } from '$lib/stores/store.js';

	let now = new Date();
	let year = now.getFullYear();

	onMount(() => {
		document.documentElement.style.setProperty('--logo', `url(${Logo})`);
	});
</script>

{#if $status}
	<section class="relative bottom-0">
		<div class="container">
			<div class="logoBox">
				<a href="/" data-sveltekit-preload-data="hover"><div class="logo" /></a>
			</div>
			<div class="box">
				<h5 class="">Seiten</h5>
				<ul class="">
					<li class=""><a class="" href="/" data-sveltekit-preload-data="hover">Home</a></li>
					<li class="">
						<a class="" href="/leistungen" data-sveltekit-preload-data="hover">Leistung</a>
					</li>
					<li class="">
						<a class="" href="/immobilien" data-sveltekit-preload-data="hover">Immobilien</a>
					</li>
					<li><a class="" href="/kontakt">Kontakt</a></li>
				</ul>
			</div>
			<div class="box">
				<h5 class="">Kontakt</h5>
				<ul class="">
					<li class="">
						<!-- svelte-ignore a11y-invalid-attribute -->
						<a class="" href="tel:+491736001987">Tel.: +49(0)173 / 6001987</a>
					</li>
					<li>
						<!-- svelte-ignore a11y-invalid-attribute -->
						<a class="" href="mailto:info@davidoff.immo">Mail: info@davidoff.immo</a>
					</li>
				</ul>
			</div>
			<div class="box">
				<h5 class="">Wichtiges</h5>
				<ul class="">
					<li class="">
						<a class="" href="/impressum" data-sveltekit-preload-data="hover">Impressum</a>
					</li>
					<li class="">
						<a class="" href="/datenschutz" data-sveltekit-preload-data="hover">Datenschutz</a>
					</li>
					<li class="" />
					<li />
				</ul>
			</div>
		</div>
		<div class="social py-16">
			<div class="flex flex-wrap -mx-4 justify-between">
				<div class="w-full text-center lg:w-auto px-4 mb-6 lg:mb-0">
					<p class="text-sm text-gray-500">© DAVIDOFF IMMOBILIEN. {year} All rights reserved.</p>
					<!--<p class="mt-5 text-sm text-gray-500">POWERED BY BYTEWAVE-SOLUTIONS</p>-->
				</div>
			</div>
		</div>
	</section>
{/if}

<style>
	:root {
		--primary-color: rgb(190, 171, 58);
	}
	.container {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		position: relative;
	}
	.container div {
		margin: 3rem;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
	}
	.container div:first-child {
		overflow: hidden;
		height: 10em;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10em;
	}
	.container div > a {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	section,
	.social {
		background: rgb(18, 11, 0);
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-top: 2px solid white;
	}
	li,
	h5,
	a {
		color: white;
		padding: 0.2rem 0;
	}
	h5 {
		text-transform: uppercase;
		padding: 0 0 1rem 0;
		font-weight: 400;
	}
	a:hover {
		color: var(--primary-color);
	}
	.logo {
		filter: brightness(0) invert(1) !important;
		position: absolute;
		width: 200px;
		height: 100% !important;
		background: var(--logo);
		background-size: cover;
		margin: 0 !important;
		background-position: center center;
	}

	@media screen and (max-width: 800px) {
		.logo {
			filter: brightness(0) invert(1) !important;
			position: absolute;
			width: 300px;
			height: 150px !important;
			background: var(--logo);
			background-size: cover;
			margin: 0 !important;

			background-position: center center;
		}
		.logoBox {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 !important;
		}
		.container {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			position: relative;
		}
	}
</style>
